function getAmplifyConfiguration() {
    const hostname = window && window.location && window.location.hostname;
    /*
    This should contain production pool information.
    */

    let AmplifyConfiguration = {
        region: 'us-east-1',
        userPoolId: 'us-east-1_43Rcqm7mi',
        userPoolWebClientId: 'l4qvk4630eaf72cbid1rg689j',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    };

    if (hostname.includes('.dev.') || hostname.includes('10.0.2.2')) {
        AmplifyConfiguration = {
            region: 'us-east-1',
            userPoolId: 'us-east-1_26fkOVwna',
            userPoolWebClientId: '533k2ovktrqec9v95vsdtsp2uu',
            authenticationFlowType: 'USER_PASSWORD_AUTH',
        };
    }

    if (hostname.includes('.qa.') || hostname.includes('localhost')) {
        AmplifyConfiguration = {
            region: 'us-east-1',
            userPoolId: 'us-east-1_0o2u1ob5Y',
            userPoolWebClientId: '59e16sbp52glua9mlam28ov80a',
            authenticationFlowType: 'USER_PASSWORD_AUTH',
        };
    }

    if (hostname.includes('.prep.')) {
        AmplifyConfiguration = {
            region: 'us-east-1',
            userPoolId: 'us-east-1_cpSbfkXsl',
            userPoolWebClientId: '3ui92n0b5q7sfv3g73lh020jdq',
            authenticationFlowType: 'USER_PASSWORD_AUTH',
        };
    }

    return AmplifyConfiguration;
}

export default getAmplifyConfiguration;
