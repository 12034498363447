import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './_FacilityDetails.scss';
import { connect } from 'react-redux';
import { getStates, fetchProvider} from '../../actions/selectattributes';
import { gaLogEvent } from '../../actions/googleAnalytics';
import { Helmet } from 'react-helmet';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { PhoneNumberLink } from '../generic/PhoneNumberLink';
import { formatPhoneNumber } from '../../utils/phoneNumbers';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { appUrls } from '../../appUrls';
import { MapModal } from './MapModal';
import ContactModal from './ContactModal';
import moment from 'moment-timezone';

const enquirePopOver = (
    <Popover id="enquirePopOver">
        <p>Click here to send an anonymous enquiry to this facility</p>
    </Popover>
);
const isTelehealthProvider = (provider) =>
    provider &&
    provider.popup &&
    provider.popup?.additional_services &&
    provider.popup?.additional_services.includes('Telehealth');


const FacilityDetails = (props) => {
    const states = useSelector(state => state.states);
    const [resolutionSize, setResolutionSize] = useState(window.innerWidth);
    const [showLoader, setShowLoader] = useState(false);
    const [show, setShow] = useState(false);
    const [mapshow, setMapShow] = useState(false);
    const [lat, setLat] = useState('');
    const [long, setLong] = useState('');
    const [organisation, setOrganisation] = useState('');
    const [listMapFacility, setListMapFacility] = useState([]);
    const [center, setCenter] = useState({});
    const [againExpressingInterestInOrganization, setAgainExpressingInterestInOrganization] = useState(null);
    const [againExpressingInterestInService, setAgainExpressingInterestInService] = useState(null);
    const [againExpressingInterestInState, setAgainExpressingInterestInState] = useState(null);
    const [againExpressingInterestInOrganizationService, setAgainExpressingInterestInOrganizationService] =
        useState(null);
    const [provider, setProvider] = useState(props.location.state ? props.location.state.provider : null);
    const viewMode = props.location.state ? props.location.state.view_mode : 'list';
    const isOpenBeds = provider?.popup?.data_source !== 'provider_directory';
    const showAvailability = provider?.show_availability == "1";
    const bedsAvailable = provider?.popup.patient_availability.count || 0;
    const nextAppointment = provider?.popup.patient_availability.next_available_appointment
        ? moment(provider?.popup.patient_availability.next_available_appointment).tz('UTC').format('M/DD/YYYY')
        : null;
    const headerRef = useRef(null);

    useEffect(() => {

        if (!provider && props.match) {
            let details = {
                state: props.match?.params?.state || '',
                service_id: props.match?.params?.service_id
            };

            fetchProvider(details)
                .then(result => {
                    setProvider(result.data[0]);
                });
        }

        window.addEventListener('popstate', handleGoBack);
        window.addEventListener('resize', updateDimensions);

        //set the map height
        setTimeout(() => {
            updateDimensions();
        }, 1000);

        return () => {
            window.removeEventListener('popstate', handleGoBack);
            window.removeEventListener('resize', updateDimensions);
        };
    }, [fetchProvider]);

    const updateDimensions = () => {
        setResolutionSize(window.innerWidth);
    };

    const handleGoBack = () => {
        sessionStorage.setItem('backFromFacilityDetails', true);
        sessionStorage.setItem('view_mode', viewMode);
        props.history.replace({
            pathname: appUrls.SEARCH_RESULT_LIST,
            state: { from: 'facility-details' },
        });
    };

    const getState = () => {
        let statename = '';
        if (states !== undefined && typeof states !== 'undefined') {
            (states?.states || states || []).forEach((st) => {
                if (st.id === provider.service_state) {
                    statename = st.code;
                }
            });
        }

        return statename;
    };

    const getState2 = () => {
        let statename = '';
        if (states !== undefined && typeof states !== 'undefined') {
            (states?.states || states || []).forEach((st) => {
                if (st.id == provider?.popup?.service_state) {
                    statename = st.code;
                }
            });
        }

        return statename;
    };

    const filterHourOutpatientType = () => {
        let hourArray = provider?.popup?.ref_hours;
        if (hourArray === undefined || hourArray === null) {
            //in case there is an error with setting provider?.popup
            hourArray = [];
        }
        hourArray = hourArray.filter(function (item) {
            return item.is_outpatient === '1';
        });

        return hourArray;
    };

    const getFormattedTime = (inputDate) => {
        if (inputDate !== 'Always') {
            var currentDate = new Date('1970-01-01T' + inputDate);

            let hours = currentDate.getHours();
            let minutes = currentDate.getMinutes();

            let ampm = hours >= 12 ? 'PM' : 'AM';

            if (minutes < 10) minutes = '0' + minutes;

            var dateString = hours + ':' + minutes + ' ' + ampm;
            return dateString;
        } else {
            return '';
        }
    };

    const handlemapShow = (event, prov) => {
        event.preventDefault();
        let center = {
            lat: parseFloat(prov.service_latitude),
            lng: parseFloat(prov.service_longitude),
        };

        setMapShow(true);
        setLat(prov.service_latitude);
        setLong(prov.service_longitude);
        setOrganisation(prov.organisation);
        setListMapFacility([prov]);
        setCenter(center);
    };

    const handleExpressInterest = (
        organizationId,
        serviceId,
        organizationServiceId,
        state,
        expressingInterestAgain
    ) => {
        props.gaLogEvent({
            category: 'Search Results',
            action: 'Contact Treatment Button',
        });
        if (expressingInterestAgain) {
            setAgainExpressingInterestInOrganization(organizationId);
            setAgainExpressingInterestInService(serviceId);
            setAgainExpressingInterestInOrganizationService(organizationServiceId);
            setAgainExpressingInterestInState(state);
        } else {
            expressInterest(organizationId, serviceId, organizationServiceId, state);
        }
    };
    const expressInterest = (organization_id, service_id, organization_services_id, state) => {
        if (props.loginDetails) {
            if (props.loginDetails.type === 'Patient') {
                setShowLoader(true);
                sessionStorage.setItem('org_id', organization_id);
                sessionStorage.setItem('service_id', service_id);
                sessionStorage.setItem('organization_services_id', organization_services_id);
                sessionStorage.setItem('state', state);
                props.history.push('/patients/rrs?new_enquiry=1'); //delay request until user
            } else {
                handleShow(organization_id, service_id, organization_services_id, state);
            }
        }
    };
    const resetAgainExpressingInterestState = () => {
        setAgainExpressingInterestInOrganization(null);
        setAgainExpressingInterestInService(null);
        setAgainExpressingInterestInOrganizationService(null);
        setAgainExpressingInterestInState(null);
    };
    const handleShow = (organization_id, service_id, organization_services_id, state) => {
        setShow(true);
        sessionStorage.setItem('org_id', organization_id);
        sessionStorage.setItem('service_id', service_id);
        sessionStorage.setItem('organization_services_id', organization_services_id);
        sessionStorage.setItem('state', state);
    };

    const handleClose = (modal) => {
        sessionStorage.removeItem('org_id');
        sessionStorage.removeItem('state');

        if (modal == 'login') {
            setShow(false);
        }
        if (modal == 'map') {
            setMapShow(false);
        }
    };

    const logEvent = (action) => {
        props.gaLogEvent({
            category: 'Search Results',
            action: action,
        });
    };

    const SearchResult = () => {
        const shouldHideContactButton =
            props.loginDetails !== undefined &&
            (props.loginDetails.type === 'Provider' ||
                props.loginDetails.type === 'Admin' ||
                props.loginDetails.type === '4a provider');

        return (
            <div className="search-result-data">
                <h3 id={provider.service_id + 'heading'}>
                    {provider.organisation} - {provider.service_name}
                </h3>
                {provider.service_details}
                <div className="address-details">
                    <div className="row">
                        <div>
                            <div className="col-md-12 Searchadd">
                                <ul className="left-details col-xs-12">
                                    <li className="col-md-3 pl-0 address">
                                        {provider.service_street_no},&nbsp;
                                        {provider.service_street_name ? <br /> : ''}
                                        {provider.service_street_name}
                                        <br />
                                        {provider.service_city},&nbsp;
                                        {getState()}&nbsp;{provider.service_zip}
                                        <span id="map-button" data-testid="map-button">
                                            <button
                                                aria-label="provider service address map button"
                                                type="button"
                                                className="btn fa-icon-blue btn-link p-0"
                                                onClick={(event) => {
                                                    handlemapShow(event, provider);
                                                }}
                                            >
                                                <span className="sr-only">
                                                    {provider.organisation} - {provider.service_name}. view more
                                                    location details
                                                </span>
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                            </button>
                                        </span>
                                    </li>
                                    <li className="col-md-3 contact-del pl-0">
                                        <PhoneNumberLink
                                            phoneNumber={formatPhoneNumber(provider.pfs_dedicated_phone_no)}
                                        />
                                    </li>

                                    {provider.org_url?.length >= 1 ? (
                                        <li className="col-md-2 website-del pl-0">
                                            <a
                                                href={provider.org_url}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                aria-label={`View Website for ${provider.organisation}`}
                                            >
                                                View Website
                                                <span className="sr-only"> for {provider.organisation}</span>
                                            </a>
                                        </li>
                                    ) : (
                                        <li
                                            className="col-md-3 pl-0 presentation"
                                            role="presentation"
                                        ></li>
                                    )}

                                    {provider.distance !== undefined && provider.distance !== null ? (
                                        <li className="Searchzip col-md-3">
                                            {parseFloat(provider.distance).toFixed(2)} miles from your zip code
                                        </li>
                                    ) : (
                                        false
                                    )}
                                    {isOpenBeds && (
                                        <>
                                            <li
                                                tabIndex="0"
                                                role="provider bed availability status"
                                                className={`mt-20 ${
                                                    provider.beds_flag === 'Yes'
                                                        ? 'current-accep pl-0 bed-availability-status'
                                                        : 'current-not-accept bed-availability-status'
                                                } col-md-3 pl-0`}
                                            >
                                                <div className="availability-status">{provider.beds_availability_status}</div>
                                                <span>Last updated on {provider.lastupdated}</span>
                                            </li>

                                            {isTelehealthProvider(provider) ? (
                                            <li
                                                tabIndex="0"
                                                role="telehealth status"
                                                className="telehealth col-md-3 pl-0"
                                            >
                                                <h3>
                                                    <img
                                                        alt="Telehealth icon"
                                                        src="/images/telehealth_icon.png"
                                                        className='telehealth-icon'
                                                    />{' '}
                                                    Offers Telehealth
                                                </h3>
                                            </li>
                                            ) : null}

                                            {isOpenBeds && showAvailability && (
                                                <li
                                                    tabIndex="0"
                                                    role="availability status"
                                                    className="availability col-md-3 pl-0"
                                                >
                                                    {bedsAvailable > 0 && (
                                                        <div id="bedsAvailable" className="bedsAvailable">Total Availability: {bedsAvailable} Beds</div>
                                                    )}
                                                    {bedsAvailable == 0 && nextAppointment && (
                                                        <div id="nextAppointment" className='nextAppointment'>
                                                            Next Available Appointment: <br/>{nextAppointment}
                                                        </div>
                                                    )}
                                                </li>
                                            )}
                                            <li
                                                className={`contact-btn col-md-3 ${
                                                    (!isTelehealthProvider(provider) && !showAvailability) ? ' col-md-offset-6' : (!isTelehealthProvider(provider) || !showAvailability ? ' col-md-offset-3' : '')
                                                }`}
                                            >
                                                <div className="row">
                                                    <div className="d-flex">
                                                        {!shouldHideContactButton ? (
                                                            <>
                                                                <OverlayTrigger
                                                                    trigger={['click']}
                                                                    placement="top"
                                                                    overlay={enquirePopOver}
                                                                    rootClose
                                                                    role="tooltip"
                                                                >
                                                                    <button
                                                                        className="btn btn-default mr-5 btn-flat"
                                                                        aria-disabled="true"
                                                                    >
                                                                        <span className="sr-only">
                                                                            Click to learn more
                                                                        </span>
                                                                        <i
                                                                            className="search-question-enquire fa fa-question-circle"
                                                                            aria-hidden="true"
                                                                            title="Click here to learn more"
                                                                        ></i>
                                                                    </button>
                                                                </OverlayTrigger>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary express-btn btn-flat"
                                                                    aria-label="Contact About Treatment"
                                                                    id={`btn-${provider.service_id}`}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleExpressInterest(
                                                                            provider.popup?.InstitutionID,
                                                                            provider.service_id,
                                                                            provider.service_id,
                                                                            provider.target_state,
                                                                            provider.requested_previously
                                                                        );
                                                                    }}
                                                                >
                                                                    Contact About Treatment
                                                                </button>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderFacilityMoreInfo = () => {
        return (
            <div className="panel panel-default facilityMoreInfo">
                <div className="panel-body">
                    <div className="service-info">
                        <table className="table table-bordered">
                            <caption className="table-heading">{provider?.popup?.service_name} Information</caption>
                            <tbody>
                            <tr>
                                <th>Facility&nbsp;</th>
                                <td> {provider?.popup?.organisation} </td>
                            </tr>
                            <tr>
                                <th>Website</th>
                                <td>
                                    {provider?.popup?.website ? (
                                        <a
                                            href={provider?.popup?.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {provider?.popup?.website}
                                        </a>
                                    ) : (
                                        'Information Not Available'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Primary Service</th>
                                <td>{provider?.popup?.service}</td>
                            </tr>
                            <tr>
                                <th>Additional Services</th>
                                <td>{provider?.popup?.additional_services}</td>
                            </tr>
                            <tr>
                                <th>Service Address</th>
                                <td>
                                    <a
                                        href="#"
                                        onClick={(event) => {
                                            handlemapShow(event, provider?.popup);
                                        }}
                                        aria-label="Navigate to provider on map"
                                    >
                                        {' '}
                                        <span className="fa fa-map-marker" aria-hidden="true"></span>
                                        <span className="sr-only">Go to provider on map</span>
                                    </a>
                                    &nbsp;
                                    {provider?.popup?.service_street_no}, {provider?.popup?.service_street_name}{' '}
                                    <br />
                                    {provider?.popup?.service_city}, {getState2()} {provider?.popup?.service_zip}
                                </td>
                            </tr>
                            <tr>
                                <th>Service Phone Number</th>
                                <td>
                                    {provider?.popup?.pfs_dedicated_phone_no ? (
                                        <>
                                            <span className="fa fa-phone" />&nbsp;
                                            <PhoneNumberLink
                                                phoneNumber={formatPhoneNumber(
                                                    provider?.popup?.pfs_dedicated_phone_no
                                                )}
                                            />
                                        </>
                                    ) : (
                                        'Information Not Available'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Substances Treated</th>
                                <td>
                                    {provider?.popup?.substances_treated
                                        ? provider?.popup?.substances_treated
                                        : 'Information Not Available'}
                                </td>
                            </tr>
                            <tr>
                                <th>Medical and Psychiatric Conditions</th>
                                <td>
                                    {provider?.popup?.medical_conditions
                                        ? provider?.popup?.medical_conditions
                                        : 'Information Not Available'}
                                </td>
                            </tr>
                            <tr>
                                <th>Special Populations</th>
                                <td>
                                    {provider?.popup?.special_conditions
                                        ? provider?.popup?.special_conditions
                                        : 'Information Not Available'}
                                </td>
                            </tr>
                            <tr>
                                <th>Gender and Age Focus</th>
                                <td>
                                    {
                                        provider?.popup?.gender_age && Object.keys(provider?.popup?.gender_age).length > 0
                                            ? <ul>
                                                {Object.entries(provider?.popup?.gender_age).map(([key, values]) => (
                                                    <li key={key}>
                                                        {key}
                                                        <ul>
                                                            {values.map((value, index) => (
                                                                <li key={index}>{value}</li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                ))}
                                            </ul>
                                            : 'Information Not Available'
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Languages Served</th>
                                <td>{provider?.popup?.languages || 'Information Not Available'}</td>
                            </tr>
                            <tr>
                                <th>Providers on Site</th>
                                <td>
                                    {provider?.popup?.providers
                                        ? provider?.popup?.providers
                                        : 'Information Not Available'}
                                </td>
                            </tr>
                            <tr>
                                <th>Payment Accepted</th>
                                <td>
                                    {provider?.popup?.payments_accepted
                                        ? provider?.popup?.payments_accepted
                                        : 'Information Not Available'}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="serveDetails">
                        <div className="col-md-6 text-left">
                            <h5>Service Details</h5>
                            <div className="detailsText">
                                <p>{provider?.popup?.Details}</p>
                            </div>
                        </div>
                    </div>

                    <div className="operatingHours">
                        {filterHourOutpatientType().length >= 1 ? (
                            <div className="col-md-8">
                                <table className="table table-bordered text-center">
                                    <caption><h5> Operating hours for Walk-In </h5></caption>
                                    <tbody>
                                    <tr>
                                        <td></td>
                                        <th className="text-center">From</th>
                                        <th className="text-center">To</th>
                                    </tr>
                                    {provider?.popup?.ref_hours.map((hours, index) => {
                                        return hours.is_outpatient === '1' ? (
                                            <tr key={hours.referral_hours_id}>
                                                <td>{hours.day}</td>
                                                {hours.all_day === '1' ? (
                                                    <React.Fragment>
                                                        <td>Always</td>
                                                        <td>Always</td>
                                                    </React.Fragment>
                                                ) : hours.hours_from === null && hours.hours_to === null ? (
                                                    <React.Fragment>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <td>{getFormattedTime(hours.hours_from)}</td>
                                                        <td>{getFormattedTime(hours.hours_to)}</td>
                                                    </React.Fragment>
                                                )}
                                            </tr>
                                        ) : (
                                            ''
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <title>Search Substance Use or Mental Health Treatment Facilities - Treatment Connection</title>
                <meta
                    name="description"
                    content="Search substance use or mental health treatment facilities near you with our free, confidential treatment locator. Enter your zip code to get started."
                ></meta>
            </Helmet>

            <Header page="list" />
            {showLoader ? <div className="se-pre-con_ajax"></div> : false}
            <section
                id="searchPage"
                className={
                    resolutionSize < 992
                        ? 'container searchlistPage facilityDetailsPage searchlistPage-mobile'
                        : 'container searchlistPage facilityDetailsPage'
                }
            >
                <div className="row" ref={headerRef} role="region">
                    <div className="col-md-12 search-list-header p-0 pl-10-desktop">
                        <div className="col-md-12 pr-10-desktop">
                            <h1>Search Substance Use or Mental Health Treatment Facilities</h1>
                        </div>
                    </div>
                </div>

                {provider && (
                    <div className="row">
                        <div className="col-md-12 p-0 pl-10-desktop">
                            <div className="col-md-12 pr-10-desktop">
                                <div className="panel panel-default">
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="back-button">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={handleGoBack}
                                                        id="back-button"
                                                        data-testid="back-button"
                                                    >
                                                        <span className="fa fa-arrow-left" aria-hidden="true"></span>
                                                    </button>
                                                    <span className="backText">Back</span>
                                                </div>
                                                <div className="panel panel-default facilityInfo">
                                                    <div className="panel-body">
                                                        <SearchResult />
                                                        </div>
                                                    </div>
                                                {renderFacilityMoreInfo()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <MapModal
                            show={mapshow}
                            lat={lat}
                            long={long}
                            center={center}
                            organisation={organisation}
                            facility_list={listMapFacility}
                            onHide={() => {
                                handleClose('map');
                            }}
                            googleApiKey={props.location.state?.googleApiKey}
                        />
                        <ContactModal
                            show={show}
                            onHide={() => {
                                handleClose('login');
                            }}
                            logEvent={(event) => {
                                logEvent(event);
                            }}
                            againExpressingInterestInOrganization={againExpressingInterestInOrganization}
                            againExpressingInterestInService={againExpressingInterestInService}
                            againExpressingInterestInOrganizationService={againExpressingInterestInOrganizationService}
                            againExpressingInterestInState={againExpressingInterestInState}
                            expressInterest={expressInterest}
                            resetAgainExpressingInterestState={resetAgainExpressingInterestState}
                            {...props}
                        />
                    </div>
                )}
            </section>
            {resolutionSize < 1200 ? '' : <Footer />}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        states: state.states,
        loginDetails: state.loginDetails,
    };
};

export default connect(
    mapStateToProps,

    {
        getStates,
        gaLogEvent,
    }
)(FacilityDetails);
