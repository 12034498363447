import { useState} from 'react';
import { gaLogEvent, gaPageView } from '../../actions/googleAnalytics';
import { PhoneNumberLink } from '../generic/PhoneNumberLink';
import { formatPhoneNumber } from '../../utils/phoneNumbers';
import { OverlayTrigger} from 'react-bootstrap';
import { appUrls } from '../../appUrls';
import { MapModal } from './MapModal';
import ContactModal from './ContactModal';
import { useSelector } from 'react-redux'; 

export const SearchResult = ({provider, isMapView, enquirePopOver, ...props}) => {
  const states = useSelector(state => state.states);
  const [resolutionSize, setResolutionSize] = useState(window.innerWidth);
  const [showLoader, setShowLoader] = useState(props.showLoader || false);
  const [show, setShow] = useState(props.show || false);
  const [mapShow, setMapShow] = useState(props.mapShow || false);
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [listMapFacility, setListMapFacility] = useState([]);
  const [center, setCenter] = useState({});
  const [googleApiKey, setGoogleApiKey ] = useState(props.googleApiKey);
  const [againExpressingInterestInOrganization, setAgainExpressingInterestInOrganization] = useState(null);
  const [againExpressingInterestInService, setAgainExpressingInterestInService] = useState(null);
  const [againExpressingInterestInState, setAgainExpressingInterestInState] = useState(null);
  const [againExpressingInterestInOrganizationService, setAgainExpressingInterestInOrganizationService] = useState(null);
  const isOpenBeds = provider.popup?.data_source !== 'provider_directory';

  const getState = () => {
      let statename = '';
      if (states !== undefined && typeof states !== 'undefined') {
          (states?.states || states || []).forEach((st) => {
              if (st.id === provider.service_state) {
                  statename = st.code;
              }
          });
      }

      return statename;
  };

  const handleServiceDetailsModal = (prov) => {
    //this.props.getMoreInfo(prov.service_id);
    gaPageView('list/service_details/' + prov.service_id);
    gaLogEvent({
        category: 'Search Results',
        action: 'More Information Button',
    });
    let pathName = appUrls.FACILITY_DETAILS +
        "/" + encodeURIComponent(prov.popup.service_state_code) +
        "/" + encodeURIComponent(prov.organisation) +
        "/" + prov.service_id;
    //selected provider will contain additional data
    props.history.push({
        pathname: pathName,
        state: {
            provider: prov,
            states: states,
            googleApiKey: googleApiKey,
            view_mode: props.view_mode,
        },
    });
  };

  const TelehealthProvider = () => {
      return (
          <h3>
              <img
                  alt="Telehealth icon"
                  src="/images/telehealth_icon.png"
                  style={{
                  width: "4vw",
                  minWidth: "20px",
                  maxWidth: "30px",
                  }}
              />{" "}
          Offers Telehealth
          </h3>
      )
  }
  
  
  const expressInterest = (organization_id, service_id, organization_services_id, state) => {
      if (props.loginDetails) {
          if (props.loginDetails.type === 'Patient') {
              setShowLoader(true);
              sessionStorage.setItem('org_id', organization_id);
              sessionStorage.setItem('service_id', service_id);
              sessionStorage.setItem('organization_services_id', organization_services_id);
              sessionStorage.setItem('state', state);
              props.history.push('/patients/rrs?new_enquiry=1'); //delay request until user
          } else {
              handleShow(organization_id, service_id, organization_services_id, state);
          }
      }
  };

  const resetAgainExpressingInterestState = () => {
      setAgainExpressingInterestInOrganization(null);
      setAgainExpressingInterestInService(null);
      setAgainExpressingInterestInOrganizationService(null);
      setAgainExpressingInterestInState(null);
  };

  const handleShow = (organization_id, service_id, organization_services_id, state) => {
      setShow(true);
      sessionStorage.setItem('org_id', organization_id);
      sessionStorage.setItem('service_id', service_id);
      sessionStorage.setItem('organization_services_id', organization_services_id);
      sessionStorage.setItem('state', state);
  };

  const handleClose = (modal) => {
      sessionStorage.removeItem('org_id');
      sessionStorage.removeItem('state');

      if (modal === 'login') {
          setShow(false);
      }
      if (modal === 'map') {
          setMapShow(false);
      }
  };

  const logEvent = (action) => {
      gaLogEvent({
          category: 'Search Results',
          action: action,
      });
  };

  
  const shouldHideContactButton =
      props.loginDetails !== undefined &&
      (props.loginDetails.type === 'Provider' ||
          props.loginDetails.type === 'Admin' ||
          props.loginDetails.type === '4a provider');

  const isTelehealthProvider = (provider) =>
    provider &&
    provider.popup &&
    provider.popup.additional_services &&
    provider.popup.additional_services.includes('Telehealth');

  return (
    <>
      <div className="search-result-data">
        <h3 id={provider.service_id + "heading"}>
          {provider.organisation} - {provider.service_name}
        </h3>
        {props.resolutionSize < 1200 && isMapView && (
          <div>
            <button
              className="btn btn-default pull-right"
              type="button"
              onClick={props.deselectPanels}
            >
              <span className="sr-only">Collapse Location Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </button>
          </div>
        )}

        <p id="textSearchDetails">
        {provider.service_details}
        </p>
        <div classs="more-info">
          <button
            id="btnGoToFacilityMoreInfo"
            aria-describedby={provider.service_id + "heading"}
            type="button"
            className="btn btn-primary btn-flat"
            onClick={() => handleServiceDetailsModal(provider)}
          >
            More Information
          </button>
        </div>

        <div className="address-details">
          <ul className={`left-details Searchadd${isMapView? '': ' d-md-flex justify-content-between'}`}>
            <li className="address" id="contentProviderAddress">
              {provider.service_street_no},&nbsp;
              {provider.service_street_name ? <br /> : ""}
              {provider.service_street_name} <br />
              {provider.service_city},&nbsp;
              {getState(provider)}&nbsp;{provider.service_zip}
              <span>
                <button
                  aria-label="provider service address map button"
                  type="button"
                  className="btn fa-icon-blue btn-link p-0"
                  onClick={(event) => props.handleMapShow(event, provider)}
                  style={{ fontSize: "1em" }}
                >
                  <span className="sr-only">
                    {provider.organisation} - {provider.service_name}. view
                    more location details
                  </span>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                </button>
              </span>
            </li>
            <li className={provider.pfs_dedicated_phone_no ? "contact-del" : "no-contact"}>
              <PhoneNumberLink
                phoneNumber={formatPhoneNumber(
                  provider.pfs_dedicated_phone_no
                )}
              />
            </li>
              {provider.org_url?.length >= 1 ? (
              <li className="website-del">
                  <a
                    href={provider.org_url}
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label={`View Website for ${provider.organisation}`}
                  >
                    View Website
                    <span className="sr-only">
                      {" "}
                      for {provider.organisation}
                    </span>
                  </a>
              </li>
              ): (
                <li
                    className="col-md-2 pl-0 presentation"
                    role="presentation"
                ></li>
              )}
            <li className="Searchzip">
            {provider.distance && (
                <span>{parseFloat(provider.distance).toFixed(2)} miles from your zip code</span>
            )}
            </li>
          </ul>
          {isOpenBeds && (
            <ul className={`openbeds-info Searchadd${isMapView? '': ' d-md-flex justify-content-between'}`}>
              <li
                tabIndex="0"
                className={`mt-20 ${
                  provider.beds_flag === "Yes"
                    ? "current-accep"
                    : "current-not-accept"
                }`}
                style={{ clear: "left" }}
              >
                <div className="availability-status">
                  {provider.beds_availability_status}
                </div>
                <span>Last updated on {provider.lastupdated}</span>
              </li>
              { isTelehealthProvider(provider) && (
                <li
                  tabIndex="0"
                  className="telehealth"
                >
                  <TelehealthProvider />
                </li>
              )}
              <li className="contact-btn" style={{ padding: 0 }}>
              {!shouldHideContactButton && (
                  <>
                      <OverlayTrigger
                          trigger={['click']}
                          placement="top"
                          overlay={enquirePopOver}
                          rootClose
                          role="tooltip"
                      >
                          <button
                            id={`btn-popover-${provider.service_id}`}
                            className="btn btn-default mr-5 btn-flat"
                            aria-disabled="true"
                          >
                              <span className="sr-only">
                                  Click to learn more
                              </span>
                              <i
                                  className="search-question-enquire fa fa-question-circle"
                                  aria-hidden="true"
                                  title="Click here to learn more"
                              ></i>
                          </button>
                      </OverlayTrigger>
                      <button
                          type="button"
                          className="btn btn-primary express-btn btn-flat"
                          aria-label="Contact About Treatment"
                          id={`btn-${provider.service_id}`}
                          onClick={(e) => {
                              e.stopPropagation();
                              props.handleExpressInterest(
                                  provider.popup.InstitutionID,
                                  provider.service_id,
                                  provider.service_id,
                                  provider.target_state,
                                  provider.requested_previously
                              );
                          }}
                      >
                          Contact About Treatment
                      </button>
                  </>
              )}
              </li>
            </ul>
          )}
        </div>
      </div>
      <MapModal
        show={mapShow}
        lat={lat}
        long={long}
        center={center}
        organisation={organisation}
        facility_list={listMapFacility}
        onHide={() => {
            handleClose('map');
        }}
        googleApiKey={googleApiKey}
    />
    <ContactModal
        show={show}
        onHide={() => {
            handleClose('login');
        }}
        logEvent={(event) => {
            logEvent(event);
        }}
        againExpressingInterestInOrganization={againExpressingInterestInOrganization}
        againExpressingInterestInService={againExpressingInterestInService}
        againExpressingInterestInOrganizationService={againExpressingInterestInOrganizationService}
        againExpressingInterestInState={againExpressingInterestInState}
        expressInterest={expressInterest}
        resetAgainExpressingInterestState={resetAgainExpressingInterestState}
        {...props}
    />
    </>                                                         
  );
};

export default SearchResult
